<template>
  <section class="mt-4">
    <header>
      <h2>{{ 1 | stepTitle }}</h2>
      <p>Le domande precedute da un asterisco sono obbligatorie. Le sezioni successive non sono compilabili se le domande obbligatorie non ottengono risposta.</p>
    </header>

    <Stepper class="mb-4" :step="1" :enable-next-step="isFirstStepCompleted" />

    <Step1Form />

    <Stepper class="my-4" :step="1" :enable-next-step="isFirstStepCompleted" />
  </section>
</template>

<script>

import { mapGetters } from 'vuex';

import stepTitleFilter from '@/filters/stepTitleFilter.js';

import Stepper from '@/views/components/Stepper.vue';
import Step1Form from '@/views/components/Steps/Step1Form.vue';

export default {
  components: {
    Stepper,
    Step1Form,
  },
  filters: {
    stepTitle: stepTitleFilter,
  },
  computed: {
    ...mapGetters('survey', {
      isFirstStepCompleted: 'isFirstStepCompleted',
    }),
  },
};

</script>

<style lang="scss">
</style>
